import { Spin } from 'antd';
import React from 'react';
import '../../Utility/css/Loader.css'
import { useLoader } from './LoadContext';
const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
const content = <div style={contentStyle} />;

interface ILoaderProps {
    loading: boolean
}

const Loader: React.FC<ILoaderProps> = ({loading}) => {

    if (!loading) return null
    return (
        <div className='loader-overlay'>
            <Spin tip="Loading" size="large">
                {content}
            </Spin>
        </div>
    )
}

export default Loader