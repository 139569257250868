import * as type from '../../types/API/itemTypes'

export function getItems(payload: any) {
    return {
        type: type.GET_ITEMS,
        payload: payload
    }
}

export function addItems(payload: any) {
    return {
        type: type.ADD_ITEM,
        payload: payload
    }
}

export function editItems(payload: any) {
    return {
        type: type.EDIT_ITEM,
        payload: payload
    }
}

export function deleteItems(payload: any) {
    return {
        type: type.DELETE_ITEM,
        payload: payload
    }
}