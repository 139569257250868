import {
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  Layout,
  Space,
  Switch,
  Table,
  Splitter,
  Pagination,
  Select,
  TableProps,
  Tag,
  Tooltip,
  Popconfirm,
  Popover,
  List,
  Checkbox,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import {
  PlusOutlined,
  DownloadOutlined,
  FastForwardFilled,
  EditOutlined,
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  CloseOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import reportData from "./Report.json";
import { TableRowSelection } from "antd/es/table/interface";
import noResult from "../../../Utility/images/noresult.png";
import "./Report.css";
import ReportType from "./ReportType";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utility/reportsUtility";

interface IReportsProps {
  documents: any
}

interface IItems {
  numberOfItems: number;
  itemValueBeforeTax: string;
  extraChargesBeforeTax: string;
  totalBeforeTax: string;
  totalTax: string;
  totalAfterTax: string;
  nonTaxableExtraCharge: string;
  tcsAmount: string;
  ProformaGrandTotal: string;
  advancePaid: string;
  amountToPay: string;
  paymentTerm: string;
  paymentDueDate: string;
}

interface DataType {
  proformaNumber: string;
  customerName: string;
  documentDate: string;
  documentType: string;
  documentStatus: string;
  poNumber: string;
  poDate: string;
  ocNumber: string;
  ocDate: string;
  ocDeliveryDate: string;
  items: IItems[];
}

const transformReportData = (data: any[]): DataType[] => {
  return data.map((item) => ({
    proformaNumber: item["proformaNumber"],
    customerName: item["customerName"],
    documentDate: item["documentDate"],
    documentType: item["documentType"],
    documentStatus: item["documentStatus"],
    poNumber: item["poNumber"],
    poDate: item["poDate"],
    ocNumber: item["ocNumber"],
    ocDate: item["ocDate"],
    ocDeliveryDate: item["ocDeliveryDate"],
    items: item[item],
    reportType: ''
  }));
};

const itemColumns = [
  {
    title: "Number of Items",
    dataIndex: "numberOfItems",
    key: "numberOfItems",
  },
  {
    title: "Item Value - Before Tax (₹)",
    dataIndex: "itemValueBeforeTax",
    key: "itemValueBeforeTax",
  },
  {
    title: "Extra Charges - Before Tax (₹)",
    dataIndex: "extraChargesBeforeTax",
    key: "extraChargesBeforeTax",
  },
  {
    title: "Total - Before Tax (₹)",
    dataIndex: "totalBeforeTax",
    key: "totalBeforeTax",
  },
  {
    title: "Total Tax (₹)",
    dataIndex: "totalTax",
    key: "totalTax",
  },
  {
    title: "Total - After Tax (₹)",
    dataIndex: "totalAfterTax",
    key: "totalAfterTax",
  },
  {
    title: "Non-taxable Extra Charges (₹)",
    dataIndex: "nonTaxableExtraCharges",
    key: "nonTaxableExtraCharges",
  },
  {
    title: "TCS Amount (₹)",
    dataIndex: "tcsAmount",
    key: "tcsAmount",
  },
  {
    title: "Proforma Grand Total (₹)",
    dataIndex: "proformaGrandTotal",
    key: "proformaGrandTotal",
  },
  {
    title: "Advance Paid (₹)",
    dataIndex: "advancePaid",
    key: "advancePaid",
  },
  {
    title: "Amount to Pay (₹)",
    dataIndex: "amountToPay",
    key: "amountToPay",
  },
  {
    title: "Payment Term",
    dataIndex: "paymentTerm",
    key: "paymentTerm",
  },
  {
    title: "Payment Due Date",
    dataIndex: "paymentDueDate",
    key: "paymentDueData",
  },
];

const Reports: React.FC<IReportsProps> = ({ documents }) => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(reportData);
  const [data, setData] = useState<any[]>([]);
  const [isSplitterActive, setIsSplitterActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [forBulkEditOpen, setIsforBulkEditOpen] = useState(false);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedProformaNumber, setSelectedProformaNumber] = useState<
    string | null
  >(null);
  const [selectedItemData, setSelectedItemData] = useState<any[]>([]);
  const [filteredReport, setFilteredReport] = useState<DataType[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [reportType, setReportType] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>('Sales Enquiry');

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Doc No.",
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: "200px",
      fixed: 'left',
      // sorter: (a, b) => a.documentNumber.localeCompare(b.documentNumber),
      render: (docNumber: string, record: any) => (
        <>
          <span
            className="link"
            onClick={() => {
              navigate("/previewDocument?documentNumber=" + docNumber);
            }}
          >
            {docNumber}{" "}
          </span>
        </>
      ),
    },
    {
      title: "Doc Type",
      dataIndex: "documentType",
      key: "documentType",
      width: "200px",
      sorter: (a, b) => a.documentType.localeCompare(b.documentType),
    },
    {
      title: "Company",
      dataIndex: "buyerName",
      key: "buyerName",
      width: "200px",
      // sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
    },
    {
      title: "Enquiry No.",
      dataIndex: "enquiryNumber",
      key: "enquiryNumber",
      width: "200px",
      // sorter: (a: any, b: any) =>
      // a.enquiryNumber.localeCompare(b.enquiryNumber),
    },
    {
      title: "OC Created",
      dataIndex: "ocCreated",
      key: "ocCreated",
      width: "200px",
      render: (ocCreated) => (ocCreated ? "Yes" : "No"),
      // sorter: (a, b) => Number(a.ocCreated) - Number(b.ocCreated),
    },
    {
      title: "Deal Status",
      dataIndex: "dealStatus",
      key: "dealStatus",
      width: "200px",
      render: (docNumber: string, record: any) => (
        <>
          {record.status == 0 && (
            <Tag color="blue" className="mt-10">
              Draft
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Deal Owner",
      dataIndex: "dealOwner",
      width: "200px",
      key: "dealOwner",
    },
    {
      title: "Next Action Date",
      dataIndex: "nextActionDate",
      key: "nextActionDate",
      width: "200px",
      // render: (text) => formatDate(text),
      // sorter: (a, b) =>
      //   new Date(a.nextActionDate).getTime() -
      //   new Date(b.nextActionDate).getTime(),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      // sorter: (a, b) =>
      //   new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => formatDate(text),
    },
    {
      title: "Action",
      key: "action",
      width: "100px",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit Document">
            <span className="actionIcons" onClick={() => { }}>
              <EditOutlined />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const filterReports = (data: DataType[], searchText: string): DataType[] => {
    return data.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const [filteredReports] = useState<DataType[]>(
    filterReports(transformReportData(reportData), searchText)
  );

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowBulkActions(true);
    } else {
      setShowBulkActions(false);
    }
  }, [selectedRowKeys, isSplitterActive]);

  useEffect(() => {
    console.log("Filtered Reports: ", filteredReports);
  }, [filteredReports]);

  const handleSplitViewToggle = (checked: boolean) => {
    setIsSplitterActive(checked);
    setSelectedRowKeys(checked ? [filteredReports[0].proformaNumber] : []);
    setSelectedProformaNumber(filteredReports[0].proformaNumber);
    const filteredItems = reportData[0].items;
    setSelectedItemData(filteredItems);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // setCurrentRowIndex(
    //   filteredReports.findIndex(
    //     (doc: any) => doc.proformaNumber[0] === newSelectedRowKeys[0]
    //   )
    // );
    // setSelectedRowKeys(newSelectedRowKeys);
    // const selectedReport = reportData.find(
    //   (doc) => doc.proformaNumber === newSelectedRowKeys[0]
    // );
    // if (selectedReport) {
    //   setSelectedItemData(selectedReport.items);
    //   console.log("Selected Report:", selectedReport);
    // } else {
    //   setSelectedItemData([]);
    // }
    updateRowSelection(newSelectedRowKeys[0] as string);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: isSplitterActive ? "radio" : "checkbox",
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const updateRowSelection = (rowKey: string) => {
    setSelectedRowKeys([rowKey]);
    const newIndex = filteredReports.findIndex(
      (doc) => doc.proformaNumber === rowKey
    );
    setCurrentRowIndex(newIndex);
    const selectedReport = reportData.find(
      (doc) => doc.proformaNumber === rowKey
    );
    if (selectedReport) {
      setSelectedItemData(selectedReport.items);
      console.log("Selected Report:", selectedReport);
    } else {
      setSelectedItemData([]);
    }
  };

  const navigateRows = (direction: "up" | "down") => {
    if (!filteredReports.length) return;

    let newIndex = currentRowIndex;
    if (direction === "up" && currentRowIndex > 0) newIndex -= 1;
    if (direction === "down" && currentRowIndex < filteredReports.length - 1)
      newIndex += 1;

    setCurrentRowIndex(newIndex);
    const newSelectedReport = filteredReports[newIndex].proformaNumber;
    updateRowSelection(newSelectedReport);
  };

  const closeSplitView = () => {
    setIsSplitterActive(false);
    setSelectedRowKeys([]);
    setLoading(false);
    setIsExpanded(false);
  };

  const filterData = () => {
    return documents.filter((document: any) => document.documentType.toLocaleLowerCase() == documentType.toLocaleLowerCase());
  }

  const renderReportTable = (): JSX.Element => {
    return (
      <>
        {filterReports?.length > 0 ? (
          <Table<DataType>
            className={isSplitterActive ? "splitterTable" : ""}
            columns={columns}
            rowKey="proformaNumber"
            dataSource={filterData()}
            bordered
            pagination={false}
            scroll={{ x: 780 }}
            sticky
            rowSelection={rowSelection}
          />
        ) : (
          <div className="noData">
            <img src={noResult} alt="documentsData" />
            <p className="mt-20">
              You have not created any documents yet. Please create a document
            </p>
          </div>
        )}
      </>
    );
  };

  const setSelectedReportType = (data: string) => {
    setReportType(data);
  }

  const renderItemTable = (): JSX.Element => (
    <Table
      className={isSplitterActive ? "splitterTable" : ""}
      columns={itemColumns}
      rowKey="amountToPay"
      dataSource={selectedItemData}
      bordered
      pagination={false}
      scroll={{ x: 2000 }}
      sticky
    />
  );

  const listData = [{
    title: 'Sales Enquiry',
    value: 'Sales Enquiry'
  }, {
    title: 'Sales Quotation',
    value: 'Sales Quotation'
  }
  ];

  const renderFilterCotent = () => {
    return (
      <>
        <List
          size="small"
          bordered
          dataSource={listData}
          renderItem={(item) => <List.Item><Checkbox/> {item.title}</List.Item>}
        />
      </>
    )
  }

  return (
    <>
      {reportType ? <Layout className="layout">
        <div>
          <Breadcrumb
            style={{
              margin: "16px 0",
              flex: 1,
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Report Type</Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* <div className="flexBox mt-20"></div> */}
        <Content className="content-section">
          <>
            <div className="mb-10">
              <div className="flexBox">
                <div>
                  <Button type="link" className="buttonAddItem">
                    <DownloadOutlined /> Download
                  </Button>
                </div>
                <div className="flexBox">
                  <div>
                    <Switch
                      onChange={handleSplitViewToggle}
                      checked={isSplitterActive}
                      className="switch-split-screen"
                    />
                    <span className="text-split">Split View</span>
                  </div>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to select report type"
                    optionFilterProp="label"
                    defaultValue={reportType}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={[
                      {
                        value: 'salesReport',
                        label: 'Sales Report',
                      },
                      {
                        value: 'inventoryReport',
                        label: 'Inventory Report',
                      },
                      {
                        value: 'purchaseReport',
                        label: 'Purchase Report',
                      },
                      {
                        value: 'paymentReport',
                        label: 'Payment Report',
                      }
                    ]}
                  />
                  <Input placeholder="Search..." style={{ width: 200 }} />
                  <Popover
                    content={renderFilterCotent()}
                    title="Filter by document type"
                    trigger="click"
                    open={showFilter}
                    placement="bottom"
                  >
                    <FilterOutlined onClick={() => { setShowFilter(true) }} />
                  </Popover>
                </div>
              </div>
            </div>
            {isSplitterActive ? (
              <Splitter
                style={{ height: 200, boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
              >
                {!isExpanded && (
                  <Splitter.Panel defaultSize="40%" min={"20%"} max={"70%"}>
                    {renderReportTable()}
                  </Splitter.Panel>
                )}
                <Splitter.Panel>
                  <div className="slicerContainer">
                    <Header className="header-split-view">
                      <div>
                        <ArrowUpOutlined
                          onClick={() => navigateRows("up")}
                          className="iconArrowUp"
                        />
                        <span className="spanIndex">
                          {currentRowIndex + 1} of {filteredReports.length}
                        </span>
                        <ArrowDownOutlined onClick={() => navigateRows("down")} />
                      </div>
                      <div>
                        {isExpanded ? (
                          <ShrinkOutlined onClick={toggleExpand} />
                        ) : (
                          <ArrowsAltOutlined onClick={toggleExpand} />
                        )}
                        <CloseOutlined onClick={() => closeSplitView()} />
                      </div>
                    </Header>
                    {selectedProformaNumber ? (
                      renderItemTable()
                    ) : (
                      <p>Select a Proforma to view details.</p>
                    )}
                  </div>
                </Splitter.Panel>
              </Splitter>
            ) : (
              renderReportTable()
            )}
            <Pagination
              total={filteredReports?.length}
              defaultPageSize={20}
              current={currentPage}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              }}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </>
        </Content>
      </Layout> : <ReportType setReportTypeValue={(data: string) => { setSelectedReportType(data) }} />}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
