import { combineReducers } from "redux";
import appLocationReducer from "./appLocationReducer";
import { getCategoriesReducer } from "./API/categoriesProductsReducer";
import login from "./login";
import { teamsReducer, addTeamsReducer, editTeamsReducer, deleteTeamsReducer } from "./API/teams";
import { getStoreReducer, addStoreReducer, editStoreReducer, deleteStoreReducer, getStoreByIdReducer, getStoreByItemsReducer } from "./API/storeReducer";
import { addAddressReducer, deleteAddressReducer, editAddressReducer, getAddressReducer, setDefaultAddressReducer } from "./API/addressReducer";
import { addBlogReducer, deleteBlogReducer, editBlogReducer, getBlogByIdReducer, getBlogsReducer } from "./API/blogs";
import { imageReducer } from "./imageUpload";
import { addUserReducer, deleteUserReducer, editUserReducer, usersReducer } from "./users";
import { addBlogCommentReducer, deleteBlogCommentReducer, approveCommentsReducer, getApprovedBlogCommentsReducer, getBlogCommentstoBeApproveReducer, getAllBlogsApprovedCommentsReducer } from "./API/commentReducer";
import { addItemsReducer, deleteItemsReducer, editItemsReducer, itemsReducer } from "./API/items";
import { addBuyerSupplierReducer, buyerSupplierReducer, deleteBuyerSupplierReducer } from "./API/buyerSupplier";
import signupReducer from "./signup";
import { addDocumentSeriesReducer, deleteDocumentSeriesReducer, documentSeriesReducer, editDocumentSeriesReducer, updateLastDocumentNumberReducer } from "./API/documentSeriesReducer";
import { addBankDetailReducer, bankDetailReducer, deleteBankDetailReducer, editBankDetailReducer } from "./API/bankDetailsReducer";
import { createDocumentReducer, getDocumentByIdReducer, getDocumentsReducer } from "./API/documentReducer";
import { getNotificationsReducer, notifyReducer } from "./API/notificationReducer";
import createDocumentUIReducer from "./UI/createDocumentReducer";
import { getAccountInfoReducer, updateAccountInfoReducer } from "./API/accountInfoReducer";
import { stockTransferReducer } from "./API/stockTransferReducer";
import { addUOMReducer, deleteUOMReducer, editUOMReducer, getUOMReducer } from "./API/uomReducer";

const UIReducers = combineReducers({
  appLocation: appLocationReducer,
  createDocumentUIData: createDocumentUIReducer,
});

const APIReducers = combineReducers({
  login: login,
  signup: signupReducer,

  addBlog: addBlogReducer,
  editBlog: editBlogReducer,
  deleteBlog: deleteBlogReducer,
  getBlogs: getBlogsReducer,
  getBlogById: getBlogByIdReducer,

  getCategories: getCategoriesReducer,
  imageUpload: imageReducer,

  addTeam: addTeamsReducer,
  getTeams: teamsReducer,
  editTeam: editTeamsReducer,
  deleteTeam: deleteTeamsReducer,

  addUOM: addUOMReducer,
  getUOM: getUOMReducer,
  editUOM: editUOMReducer,
  deleteUOM: deleteUOMReducer,

  addUser: addUserReducer,
  getUsers: usersReducer,
  editUser: editUserReducer,
  deleteUser: deleteUserReducer,

  addItems: addItemsReducer,
  getItems: itemsReducer,
  editItems: editItemsReducer,
  deleteItems: deleteItemsReducer,

  addBuyerSupplier: addBuyerSupplierReducer,
  getBuyerSupplier: buyerSupplierReducer,
  deleteBuyerSupplier: deleteBuyerSupplierReducer,

  addStore: addStoreReducer,
  getStore: getStoreReducer,
  getStoreById: getStoreByIdReducer,
  getStoreByItems: getStoreByItemsReducer,
  editStore: editStoreReducer,
  deleteStore: deleteStoreReducer,

  addAddress: addAddressReducer,
  getAddress: getAddressReducer,
  editAddress: editAddressReducer,
  deleteAddress: deleteAddressReducer,
  setDefaultAddress: setDefaultAddressReducer,

  addBlogComment: addBlogCommentReducer,
  deleteBlogComment: deleteBlogCommentReducer,
  approveComments: approveCommentsReducer,
  getApprovedBlogComments: getApprovedBlogCommentsReducer,
  getBlogCommentstoBeApprove: getBlogCommentstoBeApproveReducer,
  getAllBlogsApprovedComments: getAllBlogsApprovedCommentsReducer,

  addDocumentSeries: addDocumentSeriesReducer,
  getDocumentSeries: documentSeriesReducer,
  editDocumentSeries: editDocumentSeriesReducer,
  deleteDocumentSeries: deleteDocumentSeriesReducer,
  updateLastDocumentNumberReducer: updateLastDocumentNumberReducer,

  getDocuments: getDocumentsReducer,
  getDocumentById: getDocumentByIdReducer,
  createDocument: createDocumentReducer,

  addBankDetail: addBankDetailReducer,
  deleteBankDetail: deleteBankDetailReducer,
  editBankDetail: editBankDetailReducer,
  getBankDetail: bankDetailReducer,

  notify: notifyReducer,
  getNotifications: getNotificationsReducer,

  getAccountInfo: getAccountInfoReducer,
  updateAccountInfo: updateAccountInfoReducer,

  stockTransfer: stockTransferReducer,
});

const RootReducer = combineReducers({
  api: APIReducers,
  ui: UIReducers,
});

export default RootReducer;
