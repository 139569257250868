import * as XLSX from 'xlsx';

export const downloadExcel = (data: any, name: string) => {
    console.log(data)
    if (data.length === 0) {
        console.log("No data selected for download.");
        return;
      }
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data); // Assuming tableData is an array of objects

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, name + '.xlsx');
}