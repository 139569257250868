import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Drawer, Result, Space, Upload, Progress, Typography, notification } from "antd";
import { UploadOutlined, InboxOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { RcFile } from "antd/es/upload";
import Loader from '../../../Home/Loader/Loader'
import MicrosoftLogo from "../../../Utility/images/microsoft-icon.png"
import ItemExcelTemplate from '../../../Templates/Item-excel-template.xlsx';
import StoreExcelTemplate from '../../../Templates/Stores-excel-template.xlsx'
import CompanyExcelTemplate from '../../../../src/Templates/company-excel-template.xlsx'
import Success from '../../../Utility/images/success.png'
import './bulkUpload.css'

const { Dragger } = Upload;
const { Text, Link } = Typography

interface IBulkUploadProps {
    title: string;
    onClose: () => void;
    open: boolean;
    uploadTemplateText: string;
    templatePath: string;
    resultTitle: string;
    resultSubTitle: String;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const BulkUpload: React.FC<IBulkUploadProps> = ({ title, onClose, open, uploadTemplateText, templatePath, resultSubTitle, resultTitle }) => {
    const [uploading, setUploading] = useState(false)
    const [validationErrors, setValidationErrors] = useState<string[]>([])
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [fileError, setFileError] = useState<string | null>(null);
    const [file, setFile] = useState<RcFile | null>(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileValid, setFileValid] = useState(false);
    const [fileSizeValid, setFileSizeValid] = useState(false);
    const [messages, setMessages] = useState<string[]>([]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
    const [currentStage, setCurrentStage] = useState<string>('');

    const handleDownload = () => {
        simulateDownloadProgress();

        try {
            let downloadUrl = '';
            switch (templatePath) {
                case 'Item-excel-template':
                    downloadUrl = ItemExcelTemplate;
                    break;
                case 'Stores-excel-template':
                    downloadUrl = StoreExcelTemplate;
                    break
                case 'company-excel-template':
                    downloadUrl = CompanyExcelTemplate;
                    break
                default:
                    throw new Error("Template not found");
            }

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${templatePath}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    const simulateDownloadProgress = () => {
        setIsDownloading(true);
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setDownloadProgress(progress);
            if (progress >= 100) {
                clearInterval(interval);
                setIsDownloading(false);
            }
        }, 100);
    };

    const handleCancelUpload = () => {
        setUploadProgress(0);
        setUploading(false);
        setUploadComplete(false);
    };

    const startUploadProcess = (file: RcFile) => {
        setMessages([]);
        setFileUploaded(true);
        const isValid = validateFile(file);
        setFileValid(isValid);
        setFileSizeValid(file.size / 1024 / 1024 < 25);

        simulateStage("Uploading file...", 33, () => {
            setMessages((prev) => [...prev, "File uploaded successfully!"]);

            simulateStage("Validating file...", 66, () => {
                const isValid = validateFile(file);
                if (isValid) {
                    setMessages((prev) => [...prev, "File is valid!"]);

                    simulateStage("Checking file size...", 100, () => {
                        const isBelow25MB = file.size / 1024 / 1024 < 25;
                        setMessages((prev) =>
                            [...prev, isBelow25MB ? "File size is below 25MB!" : "File size exceeds the limit of 25MB."]
                        );
                    });
                } else {
                    setMessages((prev) => [...prev, "Invalid file format."]);
                }
            });
        });
    };

    const simulateStage = (stageMessage: string, progress: number, callback: () => void) => {
        setUploadProgress(progress);
        setTimeout(() => {
            callback();
        }, 2000);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] as RcFile;
        if (file) {
            setFile(file);
            setFileUploaded(true); 
            startUploadProcess(file); 
        } else {
            setFileUploaded(false);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length) {
            const file = droppedFiles[0] as RcFile;
            setFile(file);
            setFileUploaded(true); 
            startUploadProcess(file);
        }
    };

    const handleUpload = () => {
        if (!fileUploaded || !fileValid || !fileSizeValid) {
            setFileError("File validations are incomplete!");
            return;
        }
        if (fileError) {
            return;
        }
        setLoading(true);
        setUploading(true);
        setUploadProgress(0);
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setUploadProgress(progress);
            if (progress >= 100) {
                clearInterval(interval);
                setUploading(false);
                setUploadProgress(100);
                setTimeout(() => {
                    setLoading(false);
                    setUploadSuccess(true);
                }, 1000);
            }
        }, 500);
    };

    const validateFile = (file: RcFile) => {
        const validTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        const isValidType = validTypes.includes(file.type);
        const isValidSize = file.size / 1024 / 1024 < 25;

        if (!isValidType) {
            setFileError("Invalid file type. Only XLS, XLSX, and CSV formats are allowed.");
            return false;
        }
        if (!isValidSize) {
            setFileError("File size exceeds the limit of 25MB.");
            return false;
        }
        return true;
    };

    const resetState = () => {
        setUploading(false);
        setValidationErrors([]);
        setUploadSuccess(false);
        setLoading(false);
        setUploadProgress(0);
        setUploadComplete(false);
        setIsDownloading(false);
        setDownloadProgress(0);
        setFileError(null);
        setFile(null);
        setFileUploaded(false);
        setFileValid(false);
        setFileSizeValid(false);
        setFileError(null);
        setMessages([]);
    };

    useEffect(() => {
        if (messages.length > 0 && currentMessageIndex < messages.length) {
            const timer = setTimeout(() => {
                setCurrentMessageIndex((prevIndex) => prevIndex + 1);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [messages, currentMessageIndex]);

    return (
        <>
            <Loader loading={loading}></Loader>
            <Drawer
                title={title}
                width={720}
                onClose={() => {
                    resetState();
                    onClose();
                }}
                open={open}
                extra={
                    <Space>
                        <Button onClick={() => {
                            resetState();
                            onClose();
                        }}>Cancel</Button>
                        <Button onClick={() => handleUpload()} type="primary">Upload</Button>
                    </Space>
                }
            >
                {!uploadSuccess ? (
                    <>
                        <Alert message="You can create a bulk upload here, you simply need to download the excel template from the button below, fill it according to your need and submit it and your items will be created in bulk." />
                        <div className="link-container mt-20">
                            <div className="sub-link-container">
                                <Space className="container-download">
                                    <img
                                        src={MicrosoftLogo}
                                        className="logo-excel"
                                    />
                                    <Text>{uploadTemplateText}</Text>
                                </Space>

                                {!isDownloading ? (
                                    <Button
                                        className="download-button"
                                        onClick={handleDownload}
                                        loading={isDownloading}
                                    >
                                        {isDownloading ? 'Downloading...' : 'Download'}
                                    </Button>
                                ) : (
                                    <>
                                        <CloseOutlined
                                            onClick={handleCancelUpload}
                                            style={{ fontSize: 20, cursor: 'pointer' }}
                                        />
                                    </>
                                )}
                            </div>
                            {isDownloading ? (
                                <Progress
                                    percent={downloadProgress}
                                    status={'active'}
                                    style={{ width: '100%', marginTop: 10 }}
                                />
                            ) : (
                                <p className="text-attachment">You can download the template from here.</p>
                            )}
                        </div>
                        <div
                            className="bulk-drawer-container"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                        >
                            <div className="upload-input-container">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <UploadOutlined className="file-add-icon" /> Drag and Drop file here or Choose file
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".xls,.xlsx,.csv"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="text-container">
                                <p className="ant-upload-hint">Supported formats: XLS, XLSX, CSV</p>
                                <p>Maximum size: 25MB</p>
                            </div>
                            {file && (
                                <div className="flexbox text-container">
                                    <p className="file-name">{file.name} <span></span></p>
                                    <p>
                                        <DeleteOutlined
                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setFile(null);
                                                setMessages([]);
                                                setValidationErrors([]);
                                                setFileValid(false);
                                                setFileSizeValid(false);
                                                setFileUploaded(false);
                                                setUploadProgress(0)
                                            }}
                                        />
                                    </p>
                                </div>
                            )}
                            <div className="progress-container">
                                {uploadProgress > 0 && (
                                    <>
                                        <Progress
                                            type="circle"
                                            percent={uploadProgress}
                                            status={uploadProgress === 100 ? "success" : "active"}
                                            style={{ marginBottom: '10px' }}
                                            size={30}
                                        />
                                        <Text>{messages[currentMessageIndex]}</Text>
                                    </>
                                )}
                            </div>
                            {messages.length > 0 && (
                                <div>
                                    {messages.slice(0, currentMessageIndex + 1).map((message, index) => (
                                        <p
                                            key={index}
                                            className="paragh-message"
                                            style={{
                                                color:
                                                    (message.includes("successfully") ||
                                                        (message.includes("valid") && !message.includes("Invalid")) ||
                                                        message.includes("below 25MB"))
                                                        ? "green"
                                                        : "red"
                                            }}
                                        >
                                            {(message.includes("successfully") ||
                                                (message.includes("valid") && !message.includes("Invalid")) ||
                                                message.includes("below 25MB"))
                                                ? <CheckCircleOutlined style={{ marginRight: "5px" }} />
                                                : <CloseCircleOutlined style={{ marginRight: "5px" }} />
                                            }
                                            {message}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="result-container">
                        <Result
                            className="custom-result"
                            icon={<img src={Success} />}
                            title={resultTitle}
                            subTitle={resultSubTitle}
                        />
                    </div>
                )}
            </Drawer>
        </>
    )
}

export default BulkUpload;