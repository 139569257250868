import React, { useEffect, useRef, useState } from "react";
import type { MenuProps, TableProps } from "antd";
import { connect } from "react-redux";
import { Breadcrumb, Button, Checkbox, DatePicker, Dropdown, Input, Layout, Menu, Pagination, Popconfirm, Space, Splitter, Switch, Table, Tag, Tooltip } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import "./Document.css";
import { setDocumentCompanyData, setDocumentType } from "../../redux/actions/UI/createDocument";
import CompanyMetaDataModal from "./Documents/CompanyMetaDataModal";
import { getDocumentById, getDocuments } from "../../redux/actions/API/documents";
import noResult from "../../Utility/images/noresult.png";
import { EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, FileOutlined, ArrowUpOutlined, ArrowDownOutlined, ArrowsAltOutlined, CloseOutlined, ShrinkOutlined, FilterOutlined } from "@ant-design/icons";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { downloadExcel } from "../../Utility/images/DownloadExcel";
import { TableRowSelection } from "antd/es/table/interface";
import DocumentSlicer from "./previewDocument/DocumentSlicer";
import logo from "../../Utility/images/ease/transparent/ease-primary.png";
import BulkEdit from "./bulkEdit/BulkEdit";
import moment from "moment";
import { formatDate } from "../utility/reportsUtility";
const { RangePicker } = DatePicker;

interface IDocumentProps {
  setDocumentCompanyData: Function;
  setDocumentType: Function;
  getDocuments: Function;
  documents: any;
  documentsState: IDataResponse;
  companyId: string;
  getDocumentById: Function;
  dataToPreview: any;
  companyName: string;
}

interface DataType {
  key: string;
  documentNumber: string;
  documentType: string;
  companyName: string;
  buyerName: string;
  enquiryNumber: number;
  ocCreated: boolean;
  dealStatus: string;
  dealOwner: string;
  nextActionDate: string;
  createdAt: string;
}

const Documents: React.FC<IDocumentProps> = ({
  setDocumentCompanyData,
  setDocumentType,
  getDocuments,
  documents,
  companyId,
  documentsState,
  getDocumentById,
  dataToPreview,
  companyName,
}) => {
  const prevPropsRef = useRef<any>();
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [breadcumpTitle, setBreadcumpTitle] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const url = window.location.href;
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [isSplitterActive, setIsSplitterActive] = useState<boolean>(false);
  const [splitterLoading, setSplitterLoading] = useState(false);
  const [forBulkEditOpen, setIsforBulkEditOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean | undefined>(false); 
  const [docTypeFilter, setDocTypeFilter] = useState<string[]>([]);
  const [dealStatusFilter, setDealStatusFilter] = useState<string[]>([]);
  const [ocCreatedFilter, setOCCreatedFilter] = useState<string[]>([]); 
  const [filteredDocs, setFilteredDocs] = useState(documents);
  const [createdAtRange, setCreatedAtRange] = useState<[moment.Moment | null, moment.Moment | null] | null>(null);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowBulkActions(true);
      if (isSplitterActive)
        getDocumentById({
          documentNumber: selectedRowKeys[0].toString(),
          companyId: Number(companyId),
        });
      //setSplitterLoading(true);
    } else {
      setShowBulkActions(false);
    }
  }, [selectedRowKeys, getDocumentById, companyId, isSplitterActive]);

  useEffect(() => {
    if (dataToPreview) {
      setSplitterLoading(false);
    }
  }, [dataToPreview]);

  useEffect(() => {
    setLoading(true);
    getDocuments({ companyId: Number(companyId) });
    if (url.includes("purchase")) {
      setBreadcumpTitle("Purchase");
    } else {
      setBreadcumpTitle("Sales");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getDocuments({ companyId: Number(companyId)});
    if (url.includes("purchase")) {
      setBreadcumpTitle("Purchase");
    } else {
      setBreadcumpTitle("Sales");
    }
  }, [url]);

  useEffect(() => {
    if (
      prevPropsRef.current?.documentsState?.loading &&
      !documentsState?.loading
    ) {
      if (documents?.error?.length > 0) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      setDocumentCompanyData,
      documentsState,
      setDocumentType,
      getDocuments,
      documents,
      companyId,
    };
  }, [getDocuments, documentsState, documents]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };
    return date.toLocaleString("en-GB", options);
  };

  const filteredDocuments = documents?.filter((doc: DataType) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      doc.documentNumber?.toLowerCase().includes(searchLower) ||
      doc.documentType?.toLowerCase().includes(searchLower) ||
      doc.buyerName?.toLowerCase().includes(searchLower) ||
      doc.enquiryNumber?.toString().includes(searchLower) ||
      (doc.ocCreated ? "Yes" : "No").toLowerCase().includes(searchLower) ||
      doc.dealStatus?.toLowerCase().includes(searchLower) ||
      doc.dealOwner?.toLowerCase().includes(searchLower) ||
      formatDate(doc.nextActionDate)?.toLowerCase().includes(searchLower) ||
      formatDate(doc.createdAt)?.toLowerCase().includes(searchLower)
    );
  });

  useEffect(() => {
    if (isSplitterActive && selectedRowKeys.length > 0) {
    }
  }, [selectedRowKeys, getDocumentById, companyId, isSplitterActive]);

  const navigateRows = (direction: "up" | "down") => {
    if (!filteredDocuments.length) return;

    let newIndex = currentRowIndex;
    if (direction === "up" && currentRowIndex > 0) newIndex -= 1;
    if (direction === "down" && currentRowIndex < filteredDocuments.length - 1)
      newIndex += 1;

    setCurrentRowIndex(newIndex);
    const newSelectedDocument = filteredDocuments[newIndex].documentNumber;
    setSelectedRowKeys([newSelectedDocument]);
    setSplitterLoading(true);
  };

  const closeSplitView = () => {
    setIsSplitterActive(false);
    setSelectedRowKeys([]);
    setLoading(false);
    setIsExpanded(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSplitViewToggle = (checked: boolean) => {
    setIsSplitterActive(checked);
    setSelectedRowKeys(checked ? [filteredDocuments[0].documentNumber] : []);
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setCurrentRowIndex(filteredDocuments.findIndex((doc: any) => doc.documentNumber === newSelectedRowKeys[0]));
    setSelectedRowKeys(newSelectedRowKeys);
    if (isSplitterActive) {
      setSplitterLoading(true);
    }
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: isSplitterActive ? "radio" : "checkbox",
  };

  const handleBulkDelete = () => {
    const updatedData = selectedRowKeys.filter(
      (row: any) => !selectedRowKeys.includes(row.id)
    );
    setSelectedRowKeys([]);
  };

  const handleBulkEdit = () => {
    setIsforBulkEditOpen(true);
    // setDataToHandle(selectedData);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Doc No.",
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: "200px",
      fixed:'left',
      sorter: (a, b) => a.documentNumber.localeCompare(b.documentNumber),
      render: (docNumber: string, record: any) => (
        <>
          <span
            className="link"
            onClick={() => {
              navigate("/previewDocument?documentNumber=" + docNumber);
            }}
          >
            {docNumber}{" "}
          </span>
        </>
      ),
    },
    {
      title: "Doc Type",
      dataIndex: "documentType",
      key: "documentType",
      width: "200px",
      sorter: (a, b) => a.documentType.localeCompare(b.documentType),
    },
    {
      title: "Company",
      dataIndex: "buyerName",
      key: "buyerName",
      width: "200px",
      sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
    },
    {
      title: "Enquiry No.",
      dataIndex: "enquiryNumber",
      key: "enquiryNumber",
      width: "200px",
      sorter: (a: any, b: any) =>
        a.enquiryNumber.localeCompare(b.enquiryNumber),
    },
    {
      title: "OC Created",
      dataIndex: "ocCreated",
      key: "ocCreated",
      width: "200px",
      render: (ocCreated) => (ocCreated ? "Yes" : "No"),
      sorter: (a, b) => Number(a.ocCreated) - Number(b.ocCreated),
    },
    {
      title: "Deal Status",
      dataIndex: "dealStatus",
      key: "dealStatus",
      width: "200px",
      render: (docNumber: string, record: any) => (
        <>
          {record.status == 0 && (
            <Tag color="blue" className="mt-10">
              Draft
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Deal Owner",
      dataIndex: "dealOwner",
      width: "200px",
      key: "dealOwner",
    },
    {
      title: "Next Action Date",
      dataIndex: "nextActionDate",
      key: "nextActionDate",
      width: "200px",
      // render: (text) => formatDate(text),
      sorter: (a, b) =>
        new Date(a.nextActionDate).getTime() -
        new Date(b.nextActionDate).getTime(),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => formatDate(text),
    },
    {
      title: "Action",
      key: "action",
      width: "100px",
      fixed:"right",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit Document">
            <span className="actionIcons" onClick={() => {}}>
              <EditOutlined />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons">
                <DeleteOutlined />
              </span>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const items: MenuProps["items"] =
    breadcumpTitle == "Sales"
      ? [
          {
            label: "Sales Enquiry",
            key: "salesEnquiry",
            icon: <FileOutlined />,
          },
          {
            label: "Sales Quotation",
            key: "salesQuotation",
            icon: <FileOutlined />,
          },
          {
            label: "Order Confirmation",
            key: "orderConfirmation",
            icon: <FileOutlined />,
          },
          {
            label: "Delivery Challan",
            key: "deliveryChallan",
            icon: <FileOutlined />,
          },
          { label: "Invoice", key: "invoice", icon: <FileOutlined /> },
          { label: "Sales Return", key: "salesReturn", icon: <FileOutlined /> },
        ]
      : [
          {
            label: "Purchase Order",
            key: "purchaseOrder",
            icon: <FileOutlined />,
          },
          {
            label: "Goods Recieve Notes",
            key: "goodsRecieveNotes",
            icon: <FileOutlined />,
          },
          {
            label: "Quality Report",
            key: "qualityReport",
            icon: <FileOutlined />,
          },
          {
            label: "Purchase Invoice",
            key: "purchaseInvoice",
            icon: <FileOutlined />,
          },
          { label: "Debit Note", key: "debitNote", icon: <FileOutlined /> },
          { label: "Credit Note", key: "creditNote", icon: <FileOutlined /> },
          {
            label: "Purchase Return",
            key: "purchaseReturn",
            icon: <FileOutlined />,
          },
        ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const clickedItem: any = items.find((item: any) => item.key === e.key);
    setDocType(clickedItem?.key);
    setModalTitle(clickedItem?.label);
    setDocumentType(clickedItem?.label);
    setIsModalOpen(true);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onSubmit = (companyData: any) => {
    setDocumentCompanyData(companyData);
    navigate("/create_document?documentType=" + docType);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const combinedDataSource = searchTerm ? filteredDocuments: filteredDocs;
  const renderDocumentTable = (): JSX.Element => {
    return (
      <>
        {filteredDocuments?.length > 0 ? (
          <Table
            className={isSplitterActive ? "splitterTable" : ""}
            columns={columns}
            rowKey="documentNumber"
            dataSource={combinedDataSource} 
            bordered
            pagination={false}
            scroll={{ x: 768 }}
            sticky
            rowSelection={rowSelection}
          />
        ) : (
          <div className="noData">
            <img src={noResult} alt="documentsData" />
            <p className="mt-20">
              You have not created any documents yet. Please create a document
            </p>
          </div>
        )}
      </>
    );
  };

  const downloadSelectedRow = () => {
    const selectedData = documents
      .filter((doc: any) =>
        selectedRowKeys.map(String).includes(String(doc.documentNumber))
      )
      .map((doc: any) => ({
        "Doc No.": doc?.documentNumber,
        "Doc Type": doc?.documentType,
        Company: doc?.buyerName,
        "Enquiry No.": doc?.enquiryNumber,
        "OC Created": doc?.OCDate ? "Yes" : "No",
        "Deal Status": "Deal Status",
        "Deal Owner": "Deal Owner",
        "Next Action Date": doc?.replyDate || "N/A",
        "Created Date": doc?.createdAt,
      }));
    downloadExcel(selectedData, "Selected_Documents");
  };

  const handleDocTypeFilterChange = (selectedValues: string[]) => {
    setDocTypeFilter(selectedValues);
  };
  
  const handleDealStatusFilterChange = (selectedValues: string[]) => {
    setDealStatusFilter(selectedValues);
  };
  
  const handleOCCreatedFilterChange = (selectedValues: string[]) => {
    setOCCreatedFilter(selectedValues);
  };
  
const applyFilters = () => {
  let filtered = documents;

  if (docTypeFilter.length > 0) {
    filtered = filtered.filter((doc: { documentType: string }) =>
      docTypeFilter.includes(doc.documentType)
    );
  }

  if (dealStatusFilter.length > 0) {
    filtered = filtered.filter((doc: { dealStatus: string }) =>
      dealStatusFilter.includes(doc.dealStatus)
    );
  }

  if (ocCreatedFilter.length > 0) {
    filtered = filtered.filter((doc: { ocCreated: any }) =>
      ocCreatedFilter.includes(doc.ocCreated ? "Yes" : "No")
    );
  }

  if (createdAtRange) {
    const [start, end] = createdAtRange;
    filtered = filtered.filter((doc: DataType) => {
      const createdAtDate = moment(doc.createdAt);
      return start && end
        ? createdAtDate.isSameOrAfter(start, 'day') && createdAtDate.isSameOrBefore(end, 'day')
        : true;
    });
  }
  return filtered;
};

const handleApplyFilters = () => {
  const filteredData = applyFilters(); 
  setFilteredDocs(filteredData);
};

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(event.target.value);
};

  const renderFilterMenu = (
    <Menu
      style={{width: 270,  padding: '10px' }}
      items={[
        {
          key: '1',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Filter by Doc Type</strong>
              <Checkbox.Group onChange={handleDocTypeFilterChange}>
                <Checkbox value="Sales Enquiry">Sales Enquiry</Checkbox>
                <Checkbox value="Sales Quotation">Sales Quotation</Checkbox>
                <Checkbox value="Order Confirmation">Order Confirmation</Checkbox>
                <Checkbox value="Delivery Challan">Delivery Challan</Checkbox>
                <Checkbox value="Invoice">invoice</Checkbox>
                <Checkbox value="Sales Return">Sales Return</Checkbox> 
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Filter by Deal Status</strong>
              <Checkbox.Group
                style={{ display: 'block', marginTop: 8 }}
                onChange={handleDealStatusFilterChange}
              >
                <Checkbox value="draft">Draft</Checkbox>
                <Checkbox value="sent">Sent</Checkbox>
                <Checkbox value="partiallyDispatch">Partially Dispatch</Checkbox>
                <Checkbox value="dispatch">Dispatch</Checkbox>
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>OC Created</strong>
              <Checkbox.Group
                style={{ display: 'block', marginTop: 8 }} 
                onChange={handleOCCreatedFilterChange}
              >
                <Checkbox value="yes">Yes</Checkbox>
                <Checkbox value="no">No</Checkbox> 
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '4',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Date Range</strong>
              <DatePicker.RangePicker 
                onChange={(dates, dateStrings) => {
                  // Handle date range selection
                }}
              />
            </div>
          ),
        },
        {
          key: '5',
          label: (
            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout className="layout">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>{breadcumpTitle}</Breadcrumb.Item>
            <Breadcrumb.Item>Documents</Breadcrumb.Item>
          </Breadcrumb>
          <div className="mt-10"></div>
        </div>

        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div>
                <Space wrap>
                  <Dropdown menu={menuProps} trigger={["click"]}>
                    <Button type="link">
                      <PlusOutlined /> Create Document
                    </Button>
                  </Dropdown>
                </Space>
                <Button
                  type="link"
                  onClick={downloadSelectedRow}
                  className="buttonAddItem"
                >
                  <DownloadOutlined /> Download
                </Button>
              </div>
              <div className="flexBox">
                {showBulkActions && selectedRowKeys.length > 1 ? (
                  <div className="flexBox">
                    <Button type="link" onClick={handleBulkEdit}>
                      <EditOutlined /> Bulk Edit
                    </Button>
                    <Button type="link" danger onClick={handleBulkDelete}>
                      <DeleteOutlined /> Bulk Delete
                    </Button>
                  </div>
                ) : (
                  []
                )}
                <div>
                  <Switch
                    onChange={handleSplitViewToggle}
                    checked={isSplitterActive}
                    className="switch-split-screen"
                  />
                  <span className="text-split">Split View</span>
                </div>
                <Dropdown overlay={renderFilterMenu} trigger={["click"]}>
                  <Tooltip title="Filter">
                    <Button
                      type="link"
                      onClick={() => setFilterDropdownVisible(true)}
                      icon={<FilterOutlined />}
                    />
                  </Tooltip>
                </Dropdown>
                <Input
                  placeholder="Search..."
                  style={{ width: 200 }}
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          {isSplitterActive ? (
            <Splitter
              style={{ height: 200, boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
            >
              {!isExpanded && (
                <Splitter.Panel defaultSize="40%" min={"20%"} max={"70%"}>
                  {renderDocumentTable()}
                </Splitter.Panel>
              )}
              <Splitter.Panel>
                <div className="slicerContainer">
                  <Header className="header-split-view">
                    <div>
                      <ArrowUpOutlined
                        onClick={() => navigateRows("up")}
                        className="iconArrowUp"
                      />
                      <span className="spanIndex">
                        {currentRowIndex + 1} of {filteredDocuments.length}
                      </span>
                      <ArrowDownOutlined onClick={() => navigateRows("down")} />
                    </div>
                    <div>
                      {isExpanded ? (
                        <ShrinkOutlined onClick={toggleExpand} />
                      ) : (
                        <ArrowsAltOutlined onClick={toggleExpand} />
                      )}
                      <CloseOutlined onClick={() => closeSplitView()} />
                    </div>
                  </Header>
                  <div className="documentSlicerPanel">
                    {splitterLoading && (
                      <div className="slicerLoader">
                        <Loader loading={splitterLoading} />
                      </div>
                    )}
                    <DocumentSlicer
                      dataToPreview={dataToPreview}
                      companyName={companyName}
                      logo={logo}
                    />
                  </div>
                </div>
              </Splitter.Panel>
            </Splitter>
          ) : (
            renderDocumentTable()
          )}
          <BulkEdit
            visible={forBulkEditOpen}
            onClose={() => setIsforBulkEditOpen(false)}
            selectedItems={selectedRowKeys}
            title={"Documents Bulk Edit"}
          />
          <Pagination
            total={filteredDocuments?.length}
            defaultPageSize={20}
            current={currentPage}
            pageSize={pageSize}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
            showSizeChanger
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ textAlign: "center", marginTop: 20 }}
          />
          <CompanyMetaDataModal
            title={modalTitle}
            isModalOpen={isModalOpen}
            handleSubmit={onSubmit}
            handleCancel={handleCancel}
          />
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
  documentsState: state.api.getDocuments,
  companyId: state.api.login?.data?.companyId,
  companyName: state.api.login.data.companyName,
  dataToPreview: state.api.getDocumentById?.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentCompanyData: (payload: any) => dispatch(setDocumentCompanyData(payload)),
  setDocumentType: (payload: any) => dispatch(setDocumentType(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
  getDocumentById: (payload: any) => dispatch(getDocumentById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
