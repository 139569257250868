import { Breadcrumb, Button, Checkbox, Dropdown, FormInstance, Layout, Menu, notification, Pagination, Popconfirm, Radio, Slider, Table, Tag, Tooltip } from "antd";
import { Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { addItems, deleteItems, editItems, getItems } from "../../../redux/actions/API/items";
import { AppConstants } from "../../../Appconstants";
import Loader from "../../../Home/Loader/Loader";
import { EditOutlined, DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined, PlusOutlined, SettingOutlined, DownloadOutlined, UploadOutlined, SwapOutlined, ClockCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import "./ItemManagement.css";
import ManageCategories from "../manageCategories/manageCategories";
import { downloadExcel } from "../../../Utility/images/DownloadExcel";
import BulkUpload from "../bulk-upload/BulkUpload"; 
import { itemsCategoryOptions, taxOptions, taxTypeOptions } from "../../utility/itemsUtility";
import StockTransferMetaData from "../StockTransfer";
import HistoryMetaData from "../History/History";  
import { TableRowSelection } from "antd/es/table/interface";
import ManageUOM from "../../Components/manage-uom/manageUOM";
import BulkEdit from "../bulkEdit/BulkEdit";
import { getUOM } from "../../../redux/actions/API/uomAction";
import type { ColumnType } from "antd/lib/table";
import { FilterOutlined } from '@ant-design/icons';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IStore {
  id: number;
  name: string;
}

interface IItemProps {
  itemsData: any[],
  addItems: Function,
  getItems: Function,
  editItems: Function,
  userId: Number,
  addItemState: IDataResponse,
  getItemState: IDataResponse,
  editItemState: IDataResponse,
  companyId: Number,
  deleteItems: Function,
  getUOM: Function,
  deleteItemState: IDataResponse,
  storeData: IStore[],
  UOMData: any,
}

interface ICommandBarItems {
  icon: any,
  title: string
}

interface IItemState {
  addEditItemOpen: boolean;
  loading: boolean;
  itemId: number;
  itemName: string;
  itemType: number;
  category: number;
  taxType: number;
  tax: number;
  metricsUnit: number;
  HSNCode: string;
  price: number;
  currentStock: number;
  minStock: number;
  maxStock: number;
  description: string;
  dataToEdit: any;
  isEdit: boolean;
  isManageCategoryOpen: boolean;
  isAddManageCategoryOpen: boolean;
  isDrawerVisible: boolean;
  filteredData: any[];
  currentPage: number;
  pageSize: number;
  isStockTransferDrawerVisible: boolean,
  selectedItem: any,
  transferQuantity: number,
  quantityError: string,
  isMultipleStockTransferDrawerVisible: boolean,
  itemData: any[];
  isHistoryDrawerVisible: boolean;
  allChecked: boolean;
  singleStoreCheck: boolean;
  commandBarItems: ICommandBarItems[];
  selectedRowKeys: any[];
  showBulkActions:boolean;
  isManageUnitDrawerVisible: boolean;
  forBulkEditOpen:boolean;
  isCommentRequired:boolean; 
  showActionButton:boolean;
  showCurrentStock:boolean;
  isMinStockFilter: boolean;
  isMaxStockFilter: boolean;
  itemTypeFilter: {
    buy: boolean;
    sell: boolean;
    both: boolean;
  };
  priceRangeFilter: number[];
  dataToSet?: any;
}

class ItemManagement extends React.Component<IItemProps, IItemState> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IItemProps) {
    super(props);
    this.state = {
      addEditItemOpen: false,
      loading: true,
      itemId: 0,
      itemName: "",
      itemType: 0,
      category: 0,
      taxType: 0,
      tax: 0,
      metricsUnit: 0,
      HSNCode: "",
      price: 0,
      currentStock: 0,
      minStock: 0,
      maxStock: 0,
      description: "",
      dataToEdit: [],
      isEdit: false,
      isManageCategoryOpen: false,
      isAddManageCategoryOpen: false,
      isDrawerVisible: false,
      filteredData: [],
      currentPage: 1,
      pageSize: 10,
      isStockTransferDrawerVisible: false,
      selectedItem: null,
      transferQuantity: 0,
      quantityError: '',
      isMultipleStockTransferDrawerVisible: false,
      itemData: [],
      isHistoryDrawerVisible: false,
      isManageUnitDrawerVisible: false,
      allChecked: false,
      singleStoreCheck: false,
      commandBarItems: [{
        icon: <UploadOutlined />,
        title: 'Bulk Upload'
      }],
      selectedRowKeys: [],
      showBulkActions:false,
      forBulkEditOpen:false,
      isCommentRequired: false,
      showActionButton: false,
      showCurrentStock: false,
      isMinStockFilter: false,
      isMaxStockFilter: false,
      itemTypeFilter: {
        buy: false,
        sell: false,
        both: false,
      },
      priceRangeFilter: [0, 100000],
    };
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };
  
  componentDidMount(): void {
    this.props.getItems({ companyId: Number(this.props.companyId) }); 
  }

  componentDidUpdate(
    prevProps: Readonly<IItemProps>,
    prevState: Readonly<IItemState>,
    snapshot?: any
  ): void {
    if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ showBulkActions: this.state.selectedRowKeys.length > 0 });
    }

    if (prevProps.getItemState?.loading && !this.props.getItemState?.loading) {
      if (this.props.getItemState?.error?.length > 0) {
      } else { 
        this.setState({
          loading: false,
          filteredData: this.generateData(),
        });
      }
    }

    if (prevProps.addItemState?.loading && !this.props.addItemState?.loading) {
      if (this.props.addItemState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.addItemState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        this.props.getItems({
          companyId: this.props.companyId,
        });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
        });
        this.openNotificationWithIcon("success", "Item added successfully");
      }
    }

    if (
      prevProps.editItemState?.loading &&
      !this.props.editItemState?.loading
    ) {
      if (this.props.editItemState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.editItemState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        this.props.getItems({
          companyId: this.props.companyId,
        });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
          isEdit: false,
        });
        this.openNotificationWithIcon("success", "Item updated successfully");
      }
    }

    if (
      prevProps.deleteItemState?.loading &&
      !this.props.deleteItemState?.loading
    ) {
      this.props.getItems({
        companyId: this.props.companyId,
      });
      this.setState({
        loading: false,
      });
      this.openNotificationWithIcon("success", "Item deleted successfully");
    }
  }

  onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys
    })
  };

  rowSelection: TableRowSelection<any> = {
    onChange: this.onSelectChange,
  };

  columns = (): ColumnType<any>[] => {
    return [
      {
        title: "Item ID",
        dataIndex: "itemId",
        key: "itemId",
        width: '95px',
        fixed:"left",
        sorter: (a: any, b: any) => parseFloat(a.itemId) - parseFloat(b.itemId),
      },
      {
        title: "Name",
        dataIndex: "itemName",
        key: "itemName",
        width: '125px',
        sorter: (a: any, b: any) =>
          a.itemName.trim().localeCompare(b.itemName.trim()),
      },
      {
        title: "Type",
        dataIndex: "itemType",
        key: "itemType",
        width: '65px',
        sorter: (a: any, b: any) => a.itemType.localeCompare(b.itemType),
        render: (_: any, record: any) => (
          <Tag
            color={
              record.itemType == "Buy"
                ? "green"
                : record.itemType == "Sell"
                  ? "blue"
                  : "purple"
            }
            key={1}
          >
            {record.itemType}
          </Tag>
        ),
      },
      {
        title: "Store",
        key: "store",
        dataIndex: "store",
        width: '150px',
        sorter: (a: any, b: any) => a.store.localeCompare(b.store),
        render: (store: string) => (
          <span>
            {store.split(', ').map((storeName: string, index: number) => (
              <Tag color="green" key={index}>
                {storeName}
              </Tag>
            ))}
          </span>
        ),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: '120px',
        sorter: (a: any, b: any) =>
          a.category.localeCompare(b.category),
      },
      {
        title: "UOM",
        key: "metricsUnit",
        dataIndex: "metricsUnit",
        width: '65px',
        sorter: (a: any, b: any) => a.metricsUnit.localeCompare(b.metricsUnit),
      },
      {
        title: "HSN",
        key: "HSNCode",
        dataIndex: "HSNCode",
        sorter: (a: any, b: any) => a.HSNCode.localeCompare(b.HSNCode),
        width: '90px',
      },
      {
        title: "Price",
        key: "price",
        dataIndex: "price",
        width: '80px',
        sorter: (a: any, b: any) => a.price - b.price,
      },
      {
        title: "Tax Type",
        key: "taxTypeToShow",
        dataIndex: "taxTypeToShow",
        width: "120px",
        sorter: (a: any, b: any) =>
          a.taxTypeToShow.localeCompare(b.taxTypeToShow),
      },
      {
        title: "Current Stock",
        key: "currentStock",
        dataIndex: "currentStock",
        width: "140px",
        sorter: (a: any, b: any) => a.currentStock - b.currentStock,
        render: (_: any, stockData: any) => {
          const icon = stockData.minStock > stockData.currentStock ? (
            <ArrowDownOutlined style={{ color: "red", marginLeft: 5 }} />
          ) : stockData.maxStock < stockData.currentStock ? (
            <ArrowUpOutlined style={{ color: "green", marginLeft: 5 }} />
          ) : null;
      
          return (
            <div className="alignedFlex">
              <div>{stockData.currentStock}</div>
              {icon}
            </div>
          );
        },
      },
      {
        title: "Min Stock",
        key: "minStock",
        dataIndex: "minStock",
        width: "115px",
        sorter: (a: any, b: any) => a.minStock - b.minStock,
      },
      {
        title: "Max Stock",
        key: "maxStock",
        dataIndex: "maxStock",
        sorter: (a: any, b: any) => a.maxStock - b.maxStock,
        width: "115px",
      },
      {
        title: "Actions",
        key: "action",
        width: "80px",
        fixed:"right",
        render: (_: any, record: any) => (
          <Space size="middle">
            <Tooltip title="Edit Details">
              <span
                className="actionIcons" onClick={() => {this.handleEdit(record)}}>
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Stock Transfer">
              <span className="actionIcons" onClick={() => this.handleOpenStockTransferDrawer(record)}>
                <SwapOutlined />
              </span>
            </Tooltip>
            <Tooltip title="History">
              <span className="actionIcons" onClick={() => this.openHistoryDrawer()}>
                <ClockCircleOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => this.handleDelete(record)}
                okText="Yes"
                cancelText="No"
              >
                <span className="actionIcons">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      },
    ];
  };

  handleDelete = (record: any) => {
    this.setState({loading: true});
    this.props.deleteItems({itemId: record.id});
  };

  renderItemType = (itemType: string) => {
    return (
      <>
        <Tag
          color={
            itemType == "Buy"
              ? "geekblue"
              : itemType == "Sell"
                ? "green"
                : "loser"
          }
          key={1}
        >
          {itemType}
        </Tag>
      </>
    );
  };

  generateData = () => {
    const uomCode = this.props.UOMData?.reduce((acc: any, uom: any) => {
      acc[uom.id] = uom.code;
      return acc;
    }, {});
  
    const storeNames = this.props.storeData?.reduce((acc: any, store: any) => {
      acc[store.id] = store.name;  
      return acc;
    }, {});
  
  
    let data: any[] = [];
    this.props.itemsData?.forEach((item: any) => {
      const storesList = item.storeIds
        .map((storeId: number) => storeNames[storeId] || 'N/A')
        .join(', ');
  
      data.push({
        key: item.id,
        id: item.id,
        itemId: item.itemId,
        itemName: item.itemName,
        itemType:
          item.itemType === AppConstants.ITEM_TYPES.BUY.ITEM_TYPE_ID
            ? AppConstants.ITEM_TYPES.BUY.ITEM_TYPE
            : item.itemType === AppConstants.ITEM_TYPES.SELL.ITEM_TYPE_ID
              ? AppConstants.ITEM_TYPES.SELL.ITEM_TYPE
              : AppConstants.ITEM_TYPES.BOTH.ITEM_TYPE,
        category:
          item.category === AppConstants.ITEM_CATEGORIES.RAW_MATERIALS.ITEM_CATEGORY_ID
            ? AppConstants.ITEM_CATEGORIES.RAW_MATERIALS.ITEM_CATEGORY
            : item.category === AppConstants.ITEM_CATEGORIES.FINISHED_GOODS.ITEM_CATEGORY_ID
              ? AppConstants.ITEM_CATEGORIES.FINISHED_GOODS.ITEM_CATEGORY
              : AppConstants.ITEM_CATEGORIES.CONSUMABLES.ITEM_CATEGORY,
        metricsUnit: uomCode[item.metricsUnit], 
        HSNCode: item.HSNCode,
        taxTypeToShow:
          item.taxType === AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX_ID
            ? `${AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX} (${item.tax}%)`
            : `${AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX} (${item.tax}%)`,
        taxType: item.taxType,
        tax: item.tax,
        store: storesList,
        price: item.price,
        minStock: item.minStock,
        maxStock: item.maxStock,
        currentStock: item.currentStock,
        description: item.description,
      });
    });
    return data;
  };  

  handleItemSubmit = () => {
    this.setState({loading: true});
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        let dataToSend: any = {
          itemId: values.itemId,
          itemName: values.itemName,
          itemType: Number(values.itemType),
          category: Number(values.category),
          taxType: Number(values.taxType),
          tax: Number(values.tax),
          metricsUnit: Number(values.metricsUnit),
          HSNCode: values.HSNCode,
          storeId: values.store,
          userId: this.props.userId,
          price: Number(values.price),
          currentStock: Number(values.currentStock),
          minStock: Number(values.minStock),
          maxStock: Number(values.maxStock),
          description: values.description,
          companyId: Number(this.props.companyId),
        };

        if (this.state.isEdit) {
          dataToSend.id = this.state.dataToEdit.id;
        }
        this.setState(dataToSend, () => {
          this.state.isEdit
            ? this.props.editItems(dataToSend)
            : this.props.addItems(dataToSend);
        });
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (record: any) => { 
    this.setState(
      {
        isEdit: true,
        addEditItemOpen: true,
      },
      () => {
        if (this.formRef.current) {
          const categoryId = 
          record.category === "Raw Materials" ? 1 : 
          record.category === "Finished Goods" ? 2 : 3;
          const metricsUnitId = this.props.UOMData?.find((uom: { code: any; }) => uom.code === record.metricsUnit)?.id;
          const storeId = this.props.storeData?.find(
            (store: { name: string; }) => store.name === record.store
          )?.id;

          let dataToEdit = {
            id: record.id,
            itemId: record.itemId,
            itemName: record.itemName,
            itemType: record.itemType == "Buy" ? 1 : record.itemType == "Sell" ? 2 : 3,
            category: categoryId,
            taxType: record.taxType,
            tax: record.tax, 
            metricsUnit: metricsUnitId,
            HSNCode: record.HSNCode,
            price: record.price,
            currentStock: record.currentStock,
            minStock: record.minStock,
            maxStock: record.maxStock,
            description: record.description,
            companyId: this.props.companyId,
            store: storeId, 
          };
          this.setState({dataToEdit: dataToEdit});
          this.formRef.current.setFieldsValue(dataToEdit);
        }
      }
    );
  };

  categoryDrawerStatus = (data: boolean) => {
    this.setState({
      isManageCategoryOpen: data,
    });
  };

  handleSearch = (value: string) => {
    const filteredData =
      value.trim() === ""
        ? this.generateData()
        : this.generateData().filter((item: any) =>
          Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(value.toLowerCase())));

    this.setState({filteredData,currentPage: 1});
  };

  onPageChange = (page: number, pageSize: number) => {
    this.setState({ currentPage: page, pageSize: pageSize});
  };

  OpenBulkUploadDrawer = () => {
    this.setState({ isDrawerVisible: true })
  }

  closeBulkUploadDrawer = () => {
    this.setState({ isDrawerVisible: false })
  }

  openMultipleStockTransferDrawer = () => {
    this.setState({ isMultipleStockTransferDrawerVisible: true })
  }

  closeMultipleStockTransferDrawer = () => {
    this.setState({ isMultipleStockTransferDrawerVisible: false })
  }

  openHistoryDrawer = () => {
    this.setState({ isHistoryDrawerVisible: true })
  }

  closeHistoryDrawer = () => {
    this.setState({ isHistoryDrawerVisible: false })
  }

  openManageUnitDrawer = () => {
    this.setState({ isManageUnitDrawerVisible: true })
  }

  closeManageUnitDrawer = () => {
    this.setState({ isManageUnitDrawerVisible: false })
  }

  handleOpenStockTransferDrawer = (record: any) => {
    const dataToSet = {
      id: record.id,
      itemId: record.itemId,
      itemName: record.itemName,
      companyId: this.props.companyId,
      stores: record.store,
    };
    
    this.setState({
      isMultipleStockTransferDrawerVisible: true,
      selectedItem: record,
      transferQuantity: 0,
      isCommentRequired: true,
      showActionButton: false,
      showCurrentStock: true,
      dataToSet,
    });
  };
  
  handleCloseStockTransferDrawer = () => {
    this.setState({
      isMultipleStockTransferDrawerVisible: false,
      selectedItem: null, 
    });
  };

  openDrawerWithComments = () => {
    this.setState({
      isMultipleStockTransferDrawerVisible: true,
      isCommentRequired: true,  
      showActionButton: true
    });
  };

  handleTransferQuantityChange = (value: number) => {
    const { selectedItem } = this.state;
    if (selectedItem) {
      if (value > selectedItem.currentStock) {
        this.setState({
          transferQuantity: value,
          quantityError: 'Transfer quantity cannot exceed current stock.',
        });
      } else {
        this.setState({
          transferQuantity: value,
          quantityError: '',
        });
      }
    }
  };

  handleSubmitStockTransfer = () => {
    this.handleCloseStockTransferDrawer();
    notification.success({
      message: 'Stock Transfer Successful',
      description: `The stock has been successfully transferred.`,
    });
  };

  downloadSelectedRow = () => { 
    const selectedData = this.state.filteredData
      .filter((item: any) => this.state.selectedRowKeys.includes(item.id))
      .map((item: any) => ({
        'Item ID': item.itemId,
        'Item Name': item.itemName,
        'Item Type': item.itemType,
        'UOM': item.metricsUnit,
        'HSN Code': item.HSNCode,
        'Item Category': item.category,
        Price: item.price,
        'Current Stock': item.currentStock,
        'Min Stock': item.minStock,
        'Max Stock': item.maxStock,
        Tax: item.tax,
        store: item.store, 
      }));
   
    downloadExcel(selectedData, "Selected_Items");
  };                

  handleBulkDelete = () => { 
    const updatedData = this.state.selectedRowKeys.filter(
      (row: any) => !this.state.selectedRowKeys.includes(row.id)
    );
    
  };

  handleBulkEdit = () => { 
    this.setState({
      forBulkEditOpen:true
    })
  };

  handleStockFilterChange = (checkedValues: any[]) => {
    this.setState({ 
      isMinStockFilter: checkedValues.includes('minStock'),
      isMaxStockFilter: checkedValues.includes('maxStock'),
    });
    this.applyFilters 
  };

  handleTypeFilterChange = (checkedValues: any[]) => {
    this.setState({ 
      itemTypeFilter: {
        buy: checkedValues.includes('buy'),
        sell: checkedValues.includes('sell'),
        both: checkedValues.includes('both'),
      },
    });
    this.applyFilters 
  };

  handlePriceRangeChange = (value: number[]) => {
    this.setState({ priceRangeFilter: value });
    this.applyFilters 
  };

  applyFilters = () => {
    let filteredData = this.generateData();    
    if (this.state.isMinStockFilter || this.state.isMaxStockFilter) {
      filteredData = filteredData.filter(item => {
        const meetsMinStock = this.state.isMinStockFilter ? item.minStock >= item.currentStock : true;
        const meetsMaxStock = this.state.isMaxStockFilter ? item.maxStock < item.currentStock : true;
         
        return meetsMinStock && meetsMaxStock;
      }); 
    }

    if (this.state.itemTypeFilter.buy) {
      filteredData = filteredData.filter(item => item.itemType === "Buy"); 
    }
  
    if (this.state.itemTypeFilter.sell) {
      filteredData = filteredData.filter(item => item.itemType === "Sell"); 
    }
  
    if (this.state.itemTypeFilter.both) {
      filteredData = filteredData.filter(item => item.itemType === "Both"); 
    }
  
    const [minPrice, maxPrice] = this.state.priceRangeFilter;
    filteredData = filteredData.filter(item => item.price >= minPrice && item.price <= maxPrice);

    this.setState({ filteredData});
  };

  render() {
    const { isDrawerVisible, currentPage, pageSize, filteredData, isMultipleStockTransferDrawerVisible, isHistoryDrawerVisible, isManageUnitDrawerVisible } = this.state;
    const startIndex = (currentPage - 1) * pageSize;
    const currentData = filteredData.slice(startIndex, startIndex + pageSize);

    const commandBarItems = this.state.commandBarItems.map((item: ICommandBarItems) => {
      return <Menu.Item key={item.title}>
        <span onClick={item.title == 'History' ? this.openHistoryDrawer : this.OpenBulkUploadDrawer}>
          {item.icon} {item.title}
        </span>
      </Menu.Item>
    })

    commandBarItems.push(
      <Menu.Item key="Download">
        <span onClick={this.downloadSelectedRow}>
          <DownloadOutlined /> Download
        </span>
      </Menu.Item>
    );
    
    const menu = (
      <Menu>{commandBarItems}</Menu>
    );

    const renderFilterMenu = (
      <Menu
        style={{ width: 250, padding: "10px" }}
        items={[
          {
            key: "1",
            label: (
              <div onClick={(e) => e.stopPropagation()}>
                <strong>Filter by Stock</strong>
                <Checkbox.Group
                  style={{ display: "block", marginTop: 8 }}
                  onChange={this.handleStockFilterChange}
                >
                  <Checkbox value="minStock">Min Stock</Checkbox>
                  <Checkbox value="maxStock">Max Stock</Checkbox>
                </Checkbox.Group>
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div onClick={(e) => e.stopPropagation()}>
                <strong>Filter by Type</strong>
                <Checkbox.Group
                  style={{ display: "block", marginTop: 8 }}
                  onChange={this.handleTypeFilterChange}
                >
                  <Checkbox value="buy">Buy</Checkbox>
                  <Checkbox value="sell">Sell</Checkbox>
                  <Checkbox value="both">Both</Checkbox>
                </Checkbox.Group>
              </div>
            ),
          },
          {
            key: "3",
            label: (
              <div
                onClick={(e) => e.stopPropagation()}
                style={{ width: "95%", maxWidth: "95%" }}
              >
                <strong>Price Range</strong>
                <Slider
                  range
                  defaultValue={this.state.priceRangeFilter}
                  onChange={this.handlePriceRangeChange}
                  max={100000}
                  step={500}
                  style={{ width: "100%", marginTop: 8 }}
                  tooltip={{ formatter: (value) => `₹ ${value}` }}
                />
              </div>
            ),
          },
          {
            key: "4",
            label: (
              <Button type="primary" onClick={this.applyFilters}>
                Apply Filters
              </Button>
            ),
          },
        ]}
      />
    );  
  
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout className="layout">
          <div className="flexBox mb-flexFlow">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Item Management</Breadcrumb.Item>
            </Breadcrumb>
            <div className="mt-10"></div>
          </div>
          <Content className="content-section">
            <div className="mb-10">
              <div className="flexBox">
                <div>
                  <Button
                    type="link"
                    onClick={() => this.setState({ addEditItemOpen: true })}
                    className="buttonAddItem"
                  >
                    <PlusOutlined /> Add Item
                  </Button>
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({ isManageCategoryOpen: true })
                    }
                    className="buttonCategories"
                  >
                    <SettingOutlined /> Categories
                  </Button>
                  <Button
                    type="link"
                    onClick={this.openManageUnitDrawer}
                    className="buttonAddItem"
                  >
                    <SettingOutlined /> Manage UOM
                  </Button>
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({
                        isMultipleStockTransferDrawerVisible: true,
                        isCommentRequired: true,
                        showActionButton: true,
                        showCurrentStock: false,
                      })
                    }
                    className="buttonCategories"
                  >
                    <SwapOutlined /> Stock Transfer
                  </Button>

                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button type="link" className="buttonAddItem">
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                </div>
                <div className="flexBox">
                  {this.state.showBulkActions &&
                  this.state.selectedRowKeys.length > 1 ? (
                    <div className="flexBox">
                      <Button type="link" onClick={this.handleBulkEdit}>
                        <EditOutlined /> Bulk Edit
                      </Button>
                      <Button type="link" danger>
                        <DeleteOutlined /> Bulk Delete
                      </Button>
                    </div>
                  ) : (
                    []
                  )}
                  <Dropdown overlay={renderFilterMenu} trigger={["click"]}>
                    <Tooltip title="Filter">
                      <Button type="link" icon={<FilterOutlined />} />
                    </Tooltip>
                  </Dropdown>
                  <Input
                    placeholder="Search..."
                    style={{ width: 200 }}
                    onChange={(e) => this.handleSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Table
              columns={this.columns()}
              bordered
              rowKey="id"
              dataSource={currentData}
              scroll={{ x: "max-content" }}
              pagination={false}
              sticky
              rowSelection={this.rowSelection}
            />
            <Pagination
              total={filteredData?.length}
              pageSize={pageSize}
              current={currentPage}
              onChange={this.onPageChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </Content>
        </Layout>

        <Drawer
          title={this.state.isEdit ? "Edit an Item" : "Add an Item"}
          width={720}
          onClose={() => {
            this.setState({
              addEditItemOpen: false,
              isEdit: false,
            });
            this.formRef.current?.resetFields();
          }}
          open={this.state.addEditItemOpen}
          styles={{ body: { paddingBottom: 80 } }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addEditItemOpen: false,
                    isEdit: false,
                  });
                  this.formRef.current?.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.handleItemSubmit} type="primary">
                {this.state.isEdit ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleItemSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemId"
                  label="Item ID"
                  rules={[{ required: true, message: "Please enter item ID" }]}
                >
                  <Input
                    placeholder="Please enter item ID"
                    disabled={this.state.isEdit}
                    onChange={(event: any) => {
                      this.setState({ itemId: event.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="itemName"
                  label="Item Name"
                  rules={[
                    { required: true, message: "Please enter item name" },
                  ]}
                >
                  <Input placeholder="Please enter item name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemType"
                  label="Item type"
                  rules={[
                    { required: true, message: "Please select an owner" },
                  ]}
                >
                  <Radio.Group onChange={() => {}} value={""}>
                    <Radio value={1} defaultChecked={this.state.itemType == 1}>
                      Buy
                    </Radio>
                    <Radio value={2} defaultChecked={this.state.itemType == 2}>
                      Sell
                    </Radio>
                    <Radio value={3} defaultChecked={this.state.itemType == 3}>
                      Both
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="category" label="Category">
                  <Select
                    placeholder="Please choose the category"
                    options={itemsCategoryOptions}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="metricsUnit"
                  label="Unit of Metrics"
                  rules={[{ required: true, message: "Please choose the UOM" }]}
                >
                  <Select
                    placeholder="Please choose the UOM"
                    options={this.props.UOMData?.map(
                      (item: { code: string; id: number }) => ({
                        label: item.code,
                        value: item.id,
                      })
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="store"
                  label="Store"
                  rules={[{ required: true, message: "Please choose store" }]}
                >
                  <Select
                    placeholder="Please choose store"
                    options={this.props.storeData.map((store) => ({
                      label: store.name,
                      value: store.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="HSNCode" label="HSN (Harmonised System)">
                  <Input placeholder="Please enter HSN code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      pattern: /^[0-9]*\.?[0-9]*$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <Input placeholder="Please enter unit price" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="taxType" label="Tax type">
                  <Select
                    placeholder="Please choose the tax"
                    options={taxTypeOptions}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="tax" label="Tax">
                  <Select placeholder="Please select tax">
                    {taxOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="currentStock" label="Current Stock">
                  <Input placeholder="Please enter current stock" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="minStock" label="Min Stock">
                  <Input placeholder="Please enter min stock" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="maxStock" label="Max Stock">
                  <Input placeholder="Please enter max stock" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    rows={4}
                    placeholder="Please enter description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>

        <BulkEdit
          visible={this.state.forBulkEditOpen}
          onClose={() => this.setState({ forBulkEditOpen: false })}
          selectedItems={this.state.selectedRowKeys}
          title={"Items Bulk Edit"}
        />
        <ManageCategories
          isManageCategoryOpen={this.state.isManageCategoryOpen}
          isAddManageCategoryOpen={this.state.isAddManageCategoryOpen}
          categoryDrawerStatus={this.categoryDrawerStatus}
        />
        <BulkUpload
          title="Items Bulk Upload"
          onClose={this.closeBulkUploadDrawer}
          open={isDrawerVisible}
          uploadTemplateText="Items bulk upload template"
          templatePath="Item-excel-template"
          resultTitle="Successfully created Items"
          resultSubTitle="Your excel file for items has been created successfully"
        />
        <StockTransferMetaData
          title="Stock Transfer"
          open={isMultipleStockTransferDrawerVisible}
          onClose={this.closeMultipleStockTransferDrawer}
          showCommentSection={this.state.isCommentRequired}
          showActionButton={this.state.showActionButton}
          showCurrentStock={this.state.showCurrentStock}
          dataToSet={this.state.dataToSet}
        />
        <HistoryMetaData
          title="History"
          open={isHistoryDrawerVisible}
          onClose={this.closeHistoryDrawer}
        />
        <ManageUOM
          title="Manage Unit of Measurement"
          open={isManageUnitDrawerVisible}
          onClose={this.closeManageUnitDrawer}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  UOMData: state.api.getUOM?.data?.reverse(),
  getItemState: state.api.getItems,
  addItemState: state.api.addItems,
  editItemState: state.api.editItems,
  deleteItemState: state.api.deleteItems,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  storeData: state.api.getStore?.data?.reverse(),
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  addItems: (payload: any) => dispatch(addItems(payload)),
  editItems: (payload: any) => dispatch(editItems(payload)),
  deleteItems: (payload: any) => dispatch(deleteItems(payload)),
  getUOM: (payload: any) => dispatch(getUOM(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemManagement);
