import { Breadcrumb, Button, Card, Col, Layout, Radio, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import {
  LineChartOutlined,
  PayCircleOutlined,
  ProjectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./ReportType.css";

interface IReportTypeProps {
  setReportTypeValue: any
}

const ReportType: React.FC<IReportTypeProps> = ({ setReportTypeValue }) => {
  const [selectedReportType, setSelectedReportType] = useState<string>('');
  const reportsType = [{
    title: 'Inventory Report',
    icon: <SettingOutlined />,
    description: 'Find all the inventory related reports here',
    value: 'inventoryReport'
  },
  {
    title: 'Sales Report',
    icon: <ProjectOutlined />,
    description: 'Find all the sales related reports here',
    value: 'salesReport'
  },
  {
    title: 'Purchase Report',
    icon: <LineChartOutlined />,
    description: 'Find all the purchase related reports here',
    value: 'purchaseReport'
  },
  {
    title: 'Payments Report',
    icon: <PayCircleOutlined />,
    description: 'Find all the payment related reports here',
    value: 'payementsReport'
  }];

  const onReportSelection = () => {
    console.log(selectedReportType);
    setReportTypeValue(selectedReportType);
  }

  const renderReportsType = (): any => {
    return reportsType.map((elem: any) => {
      return <Col span={6}>
        <Card className="reportCard">
          <Radio.Group
            className="radio-group-top-right"
            onChange={(elem) => {
              setSelectedReportType(elem.target.value);
            }}
            value={selectedReportType}
          >
            <Radio value={elem.value} />
          </Radio.Group>
          <div className="text-content-row">
            <div className="text-content-column">
              <h5>
                {elem.icon} {elem.title}
              </h5>
              <p>{elem.description}</p>
            </div>
          </div>
        </Card>
      </Col>
    })
  }
  return (
    <>
      <Layout className="layout">
        <div>
          <Breadcrumb
            style={{
              margin: "16px 0",
              flex: 1,
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Report Type</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content className="content-section">
          <Row gutter={24}>
            <div className="report-cards">
              {renderReportsType()}
          </div>
        </Row>
        <div className="">
          <Button
            type="primary"
            onClick={onReportSelection}
            disabled={!setSelectedReportType}
            style={{ marginTop: 16, alignItems: "self-end" }}
          >
            OK
          </Button>
        </div>
      </Content>
    </Layout >
    </>
  );
};

export default ReportType;
