import React from "react";
import { Drawer, Button, Space, Timeline, Table, Input } from "antd";
import { SmileOutlined } from "@ant-design/icons";

interface IHistoryProps {
  title: string;
  onClose: () => void;
  open: boolean;
}

const tableColumns = [
  {
    title: "Date",
    dataIndex: "changedDate",
    key: "changedDate",
    width: "120px",
  },
  {
    title: "Changed Via",
    dataIndex: "changedVia",
    key: "changedVia",
    width: "150px",
  },
  {
    title: "Item ID",
    dataIndex: "itemId",
    key: "itemId",
    width: "80px",
  },
  {
    title: "Item Name",
    dataIndex: "itemName",
    key: "itemName",
    width: "100px",
  },
  {
    title: "Previous Quantity",
    dataIndex: "previousQuantity",
    key: "previousQuantity",
    width: "120px",
  },
  {
    title: "Change Quantity",
    dataIndex: "changeQuantity",
    key: "changeQuantity",
    width: "120px",
  },
  {
    title: "Updated Quantity",
    dataIndex: "updatedQuantity",
    key: "updatedQuantity",
    width: "120px",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: "120px",
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    width: "80px",
  },
  {
    title: "Store",
    dataIndex: "store",
    key: "store",
    width: "95px",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
    width: "135px",
  },
];

const tableData = [
  {
    key: "1",
    changedDate: "29/10/24",
    changedVia: "Stock Adjustment",
    itemId: "002",
    itemName: "Item B",
    previousQuantity: "5",
    changeQuantity: "+10",
    updatedQuantity: "15",
    price: "₹ 25",
    user: "User2",
    store: "StoreB",
    comment: "Inventory adjusted",
  },
  {
    key: "2",
    changedDate: "12/10/24",
    changedVia: "Bulk Order",
    itemId: "004",
    itemName: "Item D",
    previousQuantity: "8",
    changeQuantity: "+12",
    updatedQuantity: "20",
    price: "₹ 40",
    user: "User4",
    store: "StoreD",
    comment: "New stock received",
  },
];
 
const storeTableColumns = [
  {
    title: "Date",
    dataIndex: "changedDate",
    key: "changedDate",
    width: "120px",
  },
  {
    title: "Changed Via",
    dataIndex: "changedVia",
    key: "changedVia",
    width: "120px",
  },
  {
    title: "Store Name",
    dataIndex: "storeName",
    key: "storeName",
    width: "150px",
  },
  {
    title: "Store Type",
    dataIndex: "storeType",
    key: "storeType",
    width: "120px",
  },
  {
    title: "Items",
    dataIndex: "items",
    key: "items",
    width: "120px",
  },
  {
    title: "Store Address",
    dataIndex: "storeAddress",
    key: "storeAddress",
    width: "200px",
  },
];

const storeTableData = [
  {
    key: "1",
    changedDate: "29/10/24",
    changedVia: "Store Setup",
    storeName: "Main Store",
    storeType: "Retail",
    items: "Item A, Item B",
    storeAddress: "123 Main St, City A",
  },
  {
    key: "2",
    changedDate: "12/10/24",
    changedVia: "Store Opening",
    storeName: "Warehouse 1",
    storeType: "Warehouse",
    items: "Item C, Item D",
    storeAddress: "456 Warehouse Ave, City B",
  },
  {
    key: "3",
    changedDate: "05/10/24",
    changedVia: "New Stock",
    storeName: "Outlet Store",
    storeType: "Outlet",
    items: "Item E",
    storeAddress: "789 Outlet Rd, City C",
  },
  {
    key: "4",
    changedDate: "22/10/24",
    changedVia: "Store Update",
    storeName: "Branch Store",
    storeType: "Retail",
    items: "Item F, Item G",
    storeAddress: "321 Branch Blvd, City D",
  },
];

 
const documentTableColumns = [
  {
    title: "Date",
    dataIndex: "changedDate",
    key: "changedDate",
    width: "130px",
  },
  {
    title: "Changed Via",
    dataIndex: "changedVia",
    key: "changedVia",
    width: "150px",
  },
  {
    title: "Doc No.",
    dataIndex: "docNo",
    key: "docNo",
    width: "100px",
  },
  {
    title: "Doc Type",
    dataIndex: "docType",
    key: "docType",
    width: "120px",
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    width: "160px",
  },
  {
    title: "Enquiry No.",
    dataIndex: "enquiryNo",
    key: "enquiryNo",
    width: "150px",
  },
  {
    title: "OC Created",
    dataIndex: "ocCreated",
    key: "ocCreated",
    width: "150px",
  },
  {
    title: "Deal Status",
    dataIndex: "dealStatus",
    key: "dealStatus",
    width: "120px",
  },
  {
    title: "Deal Owner",
    dataIndex: "dealOwner",
    key: "dealOwner",
    width: "120px",
  },
  {
    title: "Next Action Date",
    dataIndex: "nextActionDate",
    key: "nextActionDate",
    width: "150px",
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
    width: "150px",
  },
];

const documentTableData = [
  {
    key: "1",
    changedDate: "29/10/24",
    changedVia: "Document Creation",
    docNo: "DOC001",
    docType: "Invoice",
    company: "Company A",
    enquiryNo: "ENQ123",
    ocCreated: "Yes",
    dealStatus: "Closed",
    dealOwner: "Owner 1",
    nextActionDate: "30/10/24",
    createdDate: "01/10/24",
  },
  {
    key: "2",
    changedDate: "12/10/24",
    changedVia: "Document Update",
    docNo: "DOC002",
    docType: "Quotation",
    company: "Company B",
    enquiryNo: "ENQ456",
    ocCreated: "No",
    dealStatus: "Pending",
    dealOwner: "Owner 2",
    nextActionDate: "05/11/24",
    createdDate: "15/10/24",
  },
  {
    key: "3",
    changedDate: "05/10/24",
    changedVia: "Document Approval",
    docNo: "DOC003",
    docType: "Contract",
    company: "Company C",
    enquiryNo: "ENQ789",
    ocCreated: "Yes",
    dealStatus: "In Progress",
    dealOwner: "Owner 3",
    nextActionDate: "10/11/24",
    createdDate: "20/10/24",
  },
];

const HistoryData: React.FC<IHistoryProps> = ({ title, onClose, open }) => {
  return (
    <Drawer
      title={title}
      width={900}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Input placeholder="Search Timeline" />
          <Button onClick={onClose}>Cancel</Button>
        </Space>
      }
    >
      <Timeline>
        <Timeline.Item>
          <p>Inventory Changes:</p>
          <Table
            bordered
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </Timeline.Item>
        <Timeline.Item>
          <p>Store Information:</p>
          <Table
            bordered
            columns={storeTableColumns}
            dataSource={storeTableData}
            pagination={false}
          />
        </Timeline.Item>
        <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
          <p>Document Information:</p>
          <Table
            bordered
            columns={documentTableColumns}
            dataSource={documentTableData}
            pagination={false}
          />
        </Timeline.Item>
      </Timeline>
    </Drawer>
  );
};

export default HistoryData;
